import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        display: "flex",
        "align-items": "center",
        "flex-direction": "column",
        "min-height": "100vh",
      },
    },
    [
      _c(
        "div",
        { staticStyle: { "align-self": "center" } },
        [
          _c(
            "h1",
            {
              staticClass: "confirm-data-title",
              staticStyle: { "font-weight": "bold", "margin-top": "30px" },
            },
            [_vm._v(" " + _vm._s(_vm.$t("confirm_data_alpha")) + " ")]
          ),
          _c("p", { staticClass: "my-5 mb-12 confirm-data-subtitle" }, [
            _vm._v(" " + _vm._s(_vm.$t("confirm_for_your_security")) + " "),
          ]),
          _c("p", { staticClass: "step-count-title" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("step_count", { current: 5, last: 6 })) + " "
            ),
          ]),
          _c(VProgressLinear, {
            staticStyle: { width: "200px" },
            attrs: { color: "primary", rounded: "", height: "6", value: "200" },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            width: "1110px",
            "max-width": "100%",
          },
        },
        [
          _c(
            VCard,
            {
              staticClass: "pa-5 pt-8 ma-0",
              staticStyle: {
                height: "550px",
                "padding-bottom": "50px !important",
                "border-bottom-right-radius": "0px",
                "border-top-right-radius": "0px",
                width: "100%",
              },
              attrs: { elevation: "1", color: "white" },
            },
            [
              _c(
                VForm,
                {
                  ref: "form",
                  attrs: { "lazy-validation": _vm.lazy },
                  on: {
                    submit: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.verifyCode()
                    },
                  },
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _vm.confirmData == false
                    ? _c(
                        "div",
                        [
                          _c(
                            VDialog,
                            {
                              attrs: {
                                "max-width": "501",
                                "max-height": "463",
                              },
                              model: {
                                value: _vm.dialog,
                                callback: function ($$v) {
                                  _vm.dialog = $$v
                                },
                                expression: "dialog",
                              },
                            },
                            [
                              _c(
                                VCard,
                                {
                                  staticClass: "success-card pb-5 pt-5",
                                  staticStyle: {
                                    "background-color": "var(--white)",
                                    display: "flex",
                                    "flex-direction": "column",
                                    "justify-content": "center",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "end",
                                        float: "right",
                                      },
                                    },
                                    [
                                      _c(
                                        VBtn,
                                        {
                                          staticClass: "pa-5 pb-0",
                                          staticStyle: {
                                            "background-color": "var(--white)",
                                            display: "flex",
                                          },
                                          attrs: {
                                            "data-test":
                                              "Alpha:ConfirmData:BtnContinue",
                                            width: "50px",
                                            "x-small": "",
                                            elevation: "0",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.goToPipeline()
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            VIcon,
                                            { attrs: { color: "black" } },
                                            [_vm._v("mdi-close")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VIcon,
                                    {
                                      staticClass: "pb-0 pt-0",
                                      staticStyle: { "border-radius": "20px" },
                                      attrs: {
                                        size: "100",
                                        "x-large": "",
                                        color: "primary",
                                      },
                                    },
                                    [_vm._v("mdi-check-circle")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "py-0 px-4 validated-account-header",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("validated_account_header")
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "px-5 validated-account-text",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("validated_account_text")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    VBtn,
                                    {
                                      staticClass: "px-5 mx-5 my-5",
                                      attrs: {
                                        "data-test":
                                          "Alpha:ConfirmData:ConfirmData",
                                        id: "button-finish-confirm",
                                        outlined: "",
                                        color: "primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.goToPipeline()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("continue")) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(VRow, { staticClass: "ma-0" }, [
                            _c("span", {
                              staticClass: "message-sent-email",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t("message_sent_email_and_cellphone", {
                                    email: _vm.email_msg,
                                    cellphone: _vm.number_msg,
                                  })
                                ),
                              },
                            }),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "ma-0 mt-12",
                              staticStyle: {
                                "margin-bottom": "111px !important",
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "confirm-data-label" },
                                [_vm._v(_vm._s(_vm.$t("pin")))]
                              ),
                              _c(VTextField, {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: ["####"],
                                    expression: "['####']",
                                  },
                                ],
                                staticClass:
                                  "mb-8 confirm-data-input my-input-style",
                                staticStyle: { "max-width": "325px" },
                                attrs: {
                                  "data-test": "Alpha:ConfirmData:ConfirmPin",
                                  dense: "",
                                  rounded: "",
                                  rules: [_vm.required],
                                  outlined: "",
                                  label: "####",
                                },
                                model: {
                                  value: _vm.code,
                                  callback: function ($$v) {
                                    _vm.code = $$v
                                  },
                                  expression: "code",
                                },
                              }),
                              _vm.showResendMessage
                                ? _c("a", { staticClass: "try-again-text" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("try_again_in", {
                                          countDown: _vm.secondsToRetry,
                                        })
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                              _c(
                                "a",
                                {
                                  staticClass: "link-resend-code",
                                  class: [_vm.isDisabled ? "disabled" : ""],
                                  staticStyle: {
                                    "text-decoration": "none",
                                    "margin-top": "10px",
                                    "margin-bottom": "10px",
                                  },
                                  on: { click: _vm.resendCode },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("re_send_code")) + " "
                                  ),
                                ]
                              ),
                              _c("p"),
                            ],
                            1
                          ),
                          _c("DxaButton", {
                            staticClass: "dxa-button",
                            attrs: {
                              "data-test": "Alpha:ConfirmData:ConfirmDataAlpha",
                              type: "submit",
                              loading: _vm.loading,
                              title: _vm.$t("confirm"),
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              _vm.confirmData
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "flex-direction": "column",
                        "justify-content": "center",
                      },
                    },
                    [
                      _c("p", { staticClass: "confirm-data-title-form" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("confirm_data_new_text")) + " "
                        ),
                      ]),
                      _c(
                        VRow,
                        { staticClass: "ma-0" },
                        [
                          _c(
                            VCol,
                            {
                              staticStyle: { "padding-left": "0" },
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "confirm-data-label" },
                                [_vm._v(_vm._s(_vm.$t("single_celphone")))]
                              ),
                              _c(VTextField, {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value:
                                      _vm.User.CountryCode &&
                                      _vm.User.CountryCode.callingCodes[0] ==
                                        "55"
                                        ? "+55 (##) #####-####"
                                        : "+" +
                                          _vm.User.CountryCode.callingCodes[0] +
                                          " ##########",
                                    expression:
                                      "\n                User.CountryCode && User.CountryCode.callingCodes[0] == '55'\n                  ? '+55 (##) #####-####'\n                  : `+${User.CountryCode.callingCodes[0]} ##########`\n              ",
                                  },
                                ],
                                staticClass:
                                  "confirm-data-input my-input-style",
                                attrs: {
                                  dense: "",
                                  outlined: "",
                                  rules: [_vm.required],
                                  "hide-details": "",
                                },
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "space",
                                        32,
                                        $event.key,
                                        [" ", "Spacebar"]
                                      )
                                    ) {
                                      return null
                                    }
                                    $event.preventDefault()
                                  },
                                },
                                model: {
                                  value: _vm.User.CelPhone,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.User, "CelPhone", $$v)
                                  },
                                  expression: "User.CelPhone",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            VCol,
                            {
                              staticStyle: { "padding-left": "0" },
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "confirm-data-label" },
                                [_vm._v(_vm._s(_vm.$t("email")))]
                              ),
                              _c(VTextField, {
                                staticClass:
                                  "confirm-data-input my-input-style",
                                attrs: {
                                  color: "primary",
                                  dense: "",
                                  outlined: "",
                                  rules: [_vm.required],
                                },
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "space",
                                        32,
                                        $event.key,
                                        [" ", "Spacebar"]
                                      )
                                    ) {
                                      return null
                                    }
                                    $event.preventDefault()
                                  },
                                },
                                model: {
                                  value: _vm.User.Email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.User, "Email", $$v)
                                  },
                                  expression: "User.Email",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        VRow,
                        { staticClass: "ma-0" },
                        [
                          _c(
                            VCol,
                            { staticStyle: { "padding-left": "0" } },
                            [
                              _c(
                                VBtn,
                                {
                                  staticClass:
                                    "ma-0 mb-5 primary button-confirm-data",
                                  class: _vm.loading ? "disabled" : "",
                                  attrs: {
                                    "data-test": "Alpha:ConfirmData:NextStep",
                                    loading: _vm.loading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.createUserFromPartner()
                                    },
                                  },
                                },
                                [_c("h1", [_vm._v(_vm._s(this.$t("confirm")))])]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            VCard,
            {
              staticClass: "cardsHeight ma-0 pa-5",
              staticStyle: {
                height: "550px",
                display: "flex",
                "flex-direction": "column",
                "place-content": "space-between",
                "border-top-left-radius": "0px",
                "border-bottom-left-radius": "0px",
                "justify-content": "space-between",
                "background-color": "var(--dark)",
              },
            },
            [
              _c(
                "div",
                { staticStyle: { display: "flex", "justify-content": "end" } },
                [
                  _c("img", {
                    staticStyle: { "max-width": "50px", opacity: "0" },
                    attrs: {
                      src: require("../../../../../../../../assets/dxa-x.png"),
                      alt: "",
                    },
                  }),
                ]
              ),
              _c("div", [
                _c("img", {
                  staticStyle: {
                    "max-height": "226px",
                    transform: "scaleX(-1)",
                  },
                  attrs: {
                    src: require("../../../../../../../../assets/dxa-background-welcome-page3(1).png"),
                    alt: "",
                  },
                }),
              ]),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }