import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VForm,
    {
      ref: "form",
      staticClass: "main-content",
      attrs: { "lazy-validation": _vm.lazy },
      on: {
        submit: function ($event) {
          $event.stopPropagation()
          $event.preventDefault()
          return _vm.checkForm()
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "header-container" }, [
          _c("span", { staticClass: "header-title" }, [
            _vm._v(_vm._s(_vm.$t("your_account_tems"))),
          ]),
          _c("span", { staticClass: "header-subtitle" }, [
            _vm._v(" " + _vm._s(_vm.$t("confirm_terms_sub_tech")) + " "),
          ]),
        ]),
        _c("div", { staticClass: "step-container" }, [
          _c("span", { staticClass: "step" }, [
            _vm._v(
              _vm._s(
                _vm.$t("alpha_register_step_count", { step: 4, totalSteps: 6 })
              )
            ),
          ]),
          _c("div", { staticClass: "progress" }),
        ]),
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "items-center" }, [
            _c(
              "div",
              { staticClass: "terms-content" },
              [
                _c(
                  VDialog,
                  {
                    attrs: { persistent: "" },
                    model: {
                      value: _vm.terms,
                      callback: function ($$v) {
                        _vm.terms = $$v
                      },
                      expression: "terms",
                    },
                  },
                  [
                    _c("TermosPDFModal", {
                      attrs: {
                        type: "terms",
                        Header: _vm.$t("terms_use"),
                        Html: _vm.contractHtmlTerms,
                        Subtitle: _vm.contractAgentTerms,
                      },
                      on: {
                        agree: _vm.agree_terms,
                        decline: _vm.decline_terms,
                      },
                    }),
                  ],
                  1
                ),
                _c(VCheckbox, {
                  staticClass: "mt-12 pa-1 mb-3 alpha-checkbox",
                  staticStyle: { "background-color": "var(--white)" },
                  attrs: {
                    "data-test": "Alpha:Contracts:terms",
                    rules: [_vm.required],
                    "hide-details": "",
                    readonly: "",
                  },
                  on: { click: _vm.open_terms },
                  scopedSlots: _vm._u([
                    {
                      key: "label",
                      fn: function () {
                        return [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                color: "var(--dark)",
                                "font-size": "16px",
                              },
                            },
                            [
                              _vm._v(_vm._s(_vm.$t("read_agreed"))),
                              _c(
                                "a",
                                {
                                  staticClass: "ml-1",
                                  on: { click: _vm.open_terms },
                                },
                                [
                                  _c(
                                    "strong",
                                    {
                                      staticStyle: {
                                        color: "var(--dark)",
                                        "text-decoration": "underline",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("terms_use")))]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value: _vm.User.Terms,
                    callback: function ($$v) {
                      _vm.$set(_vm.User, "Terms", $$v)
                    },
                    expression: "User.Terms",
                  },
                }),
                _c(
                  VDialog,
                  {
                    attrs: { persistent: "" },
                    model: {
                      value: _vm.signedPortfolio,
                      callback: function ($$v) {
                        _vm.signedPortfolio = $$v
                      },
                      expression: "signedPortfolio",
                    },
                  },
                  [
                    _c("SignedPortfolioPDFModal", {
                      attrs: {
                        type: "signedPortfolio",
                        Header: _vm.$t("signed_portfolio"),
                        Html: _vm.contractHtmlManaged,
                        Subtitle: _vm.contractAgentManaged,
                      },
                      on: {
                        agree: _vm.agree_signed_portfolio,
                        decline: _vm.decline_signed_portfolio,
                      },
                    }),
                  ],
                  1
                ),
                _vm.User.Nationality == "Brazil"
                  ? _c(VCheckbox, {
                      staticClass: "mt-0 pa-1 mb-3 alpha-checkbox",
                      staticStyle: { "background-color": "var(--white)" },
                      attrs: {
                        "data-test": "Alpha:Contracts:signedPortfolio",
                        rules: [_vm.required],
                        "hide-details": "",
                        readonly: "",
                      },
                      on: { click: _vm.open_signed_portfolio },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "var(--dark)",
                                      "font-size": "16px",
                                    },
                                  },
                                  [
                                    _vm._v(_vm._s(_vm.$t("read_agreed"))),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "ml-1",
                                        on: {
                                          click: _vm.open_signed_portfolio,
                                        },
                                      },
                                      [
                                        _c(
                                          "strong",
                                          {
                                            staticStyle: {
                                              color: "var(--dark)",
                                              "text-decoration": "underline",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("signed_portfolio"))
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        414234526
                      ),
                      model: {
                        value: _vm.User.SignedPortfolio,
                        callback: function ($$v) {
                          _vm.$set(_vm.User, "SignedPortfolio", $$v)
                        },
                        expression: "User.SignedPortfolio",
                      },
                    })
                  : _vm._e(),
                _c(
                  VDialog,
                  {
                    attrs: { persistent: "" },
                    model: {
                      value: _vm.signedAlphaPortfolio,
                      callback: function ($$v) {
                        _vm.signedAlphaPortfolio = $$v
                      },
                      expression: "signedAlphaPortfolio",
                    },
                  },
                  [
                    _c("SignedAlphaPortfolioPDFModal", {
                      attrs: {
                        type: "signedAlphaPortfolio",
                        Html: _vm.contractHtmlAddendum,
                        Header: _vm.$t("alpha_register_contract", {
                          portfolioNumber: _vm.signedPortfolioNumber,
                        }),
                        Subtitle: _vm.contractAgentAddendum,
                      },
                      on: {
                        agree: _vm.agree_signed_alpha_portfolio,
                        decline: _vm.decline_signed_alpha_portfolio,
                      },
                    }),
                  ],
                  1
                ),
                _vm.User.InvestValue >= _vm.alphaPortfolioMinInvestValue
                  ? _c(VCheckbox, {
                      staticClass: "my-0 pa-1 mb-8 alpha-checkbox",
                      staticStyle: { "background-color": "var(--white)" },
                      attrs: {
                        "data-test": "Alpha:Contracts:signedAlphaPortfolio",
                        rules: [_vm.required],
                        "hide-details": "",
                        readonly: "",
                      },
                      on: { click: _vm.open_signed_alpha_portfolio },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "var(--dark)",
                                      "font-size": "16px",
                                    },
                                  },
                                  [
                                    _vm._v(_vm._s(_vm.$t("read_agreed"))),
                                    _c("a", { staticClass: "ml-1" }, [
                                      _c(
                                        "strong",
                                        {
                                          staticStyle: {
                                            color: "var(--dark)",
                                            "text-decoration": "underline",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "alpha_register_contract",
                                                {
                                                  portfolioNumber:
                                                    _vm.signedPortfolioNumber,
                                                }
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3614253105
                      ),
                      model: {
                        value: _vm.User.SignedAlphaPortfolio,
                        callback: function ($$v) {
                          _vm.$set(_vm.User, "SignedAlphaPortfolio", $$v)
                        },
                        expression: "User.SignedAlphaPortfolio",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c("div", [
              _c("div", { staticClass: "privacy-container" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("to_more_info"))),
                  _c("a", { on: { click: _vm.openPrivacyPolicy } }, [
                    _vm._v(_vm._s(_vm.$t("privacy_policy"))),
                  ]),
                ]),
                _c("p", [_vm._v(_vm._s(_vm.$t("dont_worry")))]),
                _vm.isContractSign == false
                  ? _c("p", { staticStyle: { color: "red", margin: "0px" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("need_to_sign")) + " "),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "button-next-step-container" },
              [
                _c("DxaButton", {
                  staticClass: "mx-auto my-1 mb-3 btn-continue",
                  staticStyle: { width: "270px", margin: "0 !important" },
                  attrs: {
                    "data-test": "Alpha:ContractsComponent:Next",
                    title: _vm.$t("Agree_continue"),
                  },
                  on: { function: _vm.checkForm },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }