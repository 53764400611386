import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    {
      staticClass: "ma-0 justify-center pa-0",
      class: [
        _vm.step >= _vm.alphaSteps.WELCOME && _vm.step < _vm.alphaSteps.FINISH
          ? "lessThanEight"
          : "OnlyEight",
        _vm.gs.isMobile() ? "mobileContainer" : "desktopContainer",
      ],
      attrs: { id: "register" },
    },
    [
      _c(
        VSlideYTransition,
        { attrs: { appear: "" } },
        [
          _c(
            VCard,
            {
              staticClass: "mt-0 mb-0 fill-height",
              class: [
                _vm.step >= _vm.alphaSteps.WELCOME &&
                _vm.step < _vm.alphaSteps.FINISH
                  ? "lessThan"
                  : "eight",
                _vm.gs.isMobile() ? "mobile" : "desktop",
              ],
              staticStyle: { "min-width": "100vw" },
              attrs: { light: "", elevation: "0" },
            },
            [
              _vm.step > _vm.alphaSteps.WELCOME &&
              _vm.step < _vm.alphaSteps.FINISH
                ? _c(
                    VRow,
                    {
                      staticClass: "ma-0",
                      staticStyle: {
                        height: "59px",
                        "border-bottom": "solid 1px gray",
                        "background-color": "var(--dark)",
                        display: "flex",
                        position: "relative",
                      },
                    },
                    [
                      _vm.step > _vm.alphaSteps.INVESTORINFO &&
                      _vm.gs.isMobile() &&
                      _vm.step !== _vm.alphaSteps.CONFIRMDATA
                        ? _c(
                            VCol,
                            {
                              staticClass: "back_btn pb-0 pr-0 pt-1 ml-2 mt-2",
                              attrs: {
                                cols:
                                  _vm.step == _vm.alphaSteps.INVESTORINFO
                                    ? 1
                                    : 2,
                              },
                            },
                            [
                              _c(
                                VIcon,
                                {
                                  attrs: { "x-large": "", color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      _vm.step -= 1
                                    },
                                  },
                                },
                                [_vm._v("mdi-arrow-left")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        VCol,
                        {
                          staticClass: "back_btn pb-0 pr-0 pt-1 ml-2",
                          attrs: { cols: "1" },
                        },
                        [
                          _vm.step > _vm.alphaSteps.INVESTORINFO &&
                          !_vm.gs.isMobile() &&
                          _vm.step !== _vm.alphaSteps.CONFIRMDATA
                            ? _c(
                                VIcon,
                                {
                                  attrs: { "x-large": "", color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      _vm.step -= 1
                                    },
                                  },
                                },
                                [_vm._v("mdi-arrow-left")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.gs.isMobile()
                        ? _c(
                            VCol,
                            { staticClass: "pl-0 mt-2", attrs: { cols: "4" } },
                            [
                              _c(
                                "h3",
                                {
                                  staticClass: "my-auto ml-3",
                                  staticStyle: { color: "var(--white)" },
                                  attrs: { id: "alpha-steps-header-mobile" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("alpha_register_step_count", {
                                          step: _vm.step - 1,
                                          totalSteps: 6,
                                        })
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.gs.isMobile()
                        ? _c(
                            VCol,
                            {
                              staticClass: "pa-0",
                              staticStyle: {
                                position: "absolute",
                                bottom: "0",
                              },
                              attrs: { cols: "12" },
                            },
                            [
                              _c(VProgressLinear, {
                                staticClass: "pa-0 mb-0",
                                attrs: {
                                  color: "primary",
                                  height: "2",
                                  value: ((_vm.step - 1) * 100) / 6,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.gs.isMobile()
                        ? _c(
                            VCol,
                            {
                              staticClass: "px-0 pt-1",
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                              },
                              attrs: { cols: "2" },
                            },
                            [
                              _vm.partnerLogo != ""
                                ? _c("img", {
                                    staticClass: "mx-auto",
                                    attrs: {
                                      src: _vm.gs.get_photo_path(
                                        _vm.partnerLogo
                                      ),
                                    },
                                  })
                                : _c("img", {
                                    staticStyle: {
                                      height: "34px",
                                      "max-width": "90px",
                                    },
                                    attrs: {
                                      src: require("../../../../../../assets/dxa-white.png"),
                                    },
                                  }),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "pa-0 pt-0" },
                [
                  _vm.step < _vm.alphaSteps.FINISH
                    ? _c(
                        VRow,
                        {
                          staticClass: "ma-0",
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                            "flex-direction": "column",
                          },
                        },
                        [
                          _vm.step == _vm.alphaSteps.INVESTORINFO &&
                          _vm.gs.isMobile()
                            ? _c(VCol, { attrs: { cols: "9" } }, [
                                _c(
                                  "h2",
                                  {
                                    staticClass: "px-5 text-center",
                                    staticStyle: {
                                      "font-weight": "bold",
                                      "font-size": "24px",
                                      color: "var(--dark)",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("alpha_register_4_header")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm.step == _vm.alphaSteps.DATEPICKER &&
                          _vm.gs.isMobile()
                            ? _c(VCol, [
                                _c(
                                  "h1",
                                  {
                                    staticClass: "px-5 text-center",
                                    staticStyle: { "font-weight": "bold" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("choose_date_header")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.step == _vm.alphaSteps.INVESTORINFO && _vm.gs.isMobile()
                    ? _c(
                        "p",
                        {
                          staticClass: "px-5 text-center my-3",
                          staticStyle: {
                            "font-weight": "300",
                            "font-size": "18px",
                            "margin-left": "5%",
                            "margin-right": "5%",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("alpha_register_4_subheader")) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.step == _vm.alphaSteps.DATEPICKER && _vm.gs.isMobile()
                    ? _c("h4", { staticClass: "px-5 text-center my-3" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("choose_date_subheader")) + " "
                        ),
                      ])
                    : _vm._e(),
                  _vm.gs.isMobile()
                    ? _c(
                        "div",
                        [
                          _vm.step < _vm.alphaSteps.CONTRACTS
                            ? _c("InvestorInfo", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.step == _vm.alphaSteps.INVESTORINFO,
                                    expression:
                                      "step == alphaSteps.INVESTORINFO",
                                  },
                                ],
                                attrs: { User: _vm.user },
                                on: {
                                  back: _vm.goBack,
                                  next: _vm.goForward,
                                  getPortfolioOptions: function (nationality) {
                                    return _vm.getPortfolioOptions(nationality)
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.confirmDataState
                            ? _c("ConfirmData", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.step == _vm.alphaSteps.CONFIRMDATA,
                                    expression:
                                      "step == alphaSteps.CONFIRMDATA",
                                  },
                                ],
                                attrs: {
                                  User: _vm.user,
                                  Portfolios: _vm.portfolioOptions,
                                },
                                on: { back: _vm.goBack, next: _vm.goForward },
                              })
                            : _vm._e(),
                          !_vm.loading && _vm.confirmed_data
                            ? _c("DatePicker", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.step == _vm.alphaSteps.DATEPICKER,
                                    expression: "step == alphaSteps.DATEPICKER",
                                  },
                                ],
                                attrs: { User: _vm.user },
                                on: { back: _vm.goBack, next: _vm.register },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          !_vm.gs.isMobile() &&
                          _vm.step < _vm.alphaSteps.CONTRACTS
                            ? _c("InvestorInfoDesktop", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.step == _vm.alphaSteps.INVESTORINFO,
                                    expression:
                                      "step == alphaSteps.INVESTORINFO",
                                  },
                                ],
                                attrs: { User: _vm.user },
                                on: {
                                  getPortfolioOptions: function (nationality) {
                                    return _vm.getPortfolioOptions(nationality)
                                  },
                                  back: _vm.goBack,
                                  next_step: _vm.goForward,
                                },
                              })
                            : _vm._e(),
                          _vm.confirmDataState
                            ? _c("ConfirmDataDesktop", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.step == _vm.alphaSteps.CONFIRMDATA,
                                    expression:
                                      "step == alphaSteps.CONFIRMDATA",
                                  },
                                ],
                                attrs: { User: _vm.user },
                                on: { next_step: _vm.goForward },
                              })
                            : _vm._e(),
                          !_vm.loading && _vm.confirmed_data
                            ? _c("DatePickerDesktop", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.step == _vm.alphaSteps.DATEPICKER,
                                    expression: "step == alphaSteps.DATEPICKER",
                                  },
                                ],
                                attrs: { User: _vm.user },
                                on: { next: _vm.register },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                  _vm.step == _vm.alphaSteps.CONTRACTS
                    ? _c("ContractsDesktop", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.step == _vm.alphaSteps.CONTRACTS,
                            expression: "step == alphaSteps.CONTRACTS",
                          },
                        ],
                        attrs: {
                          User: _vm.user,
                          Portfolio: _vm.portfolioOptions.filter(function (x) {
                            return x.id == _vm.user.SelectedPortfolioId
                          })[0],
                          PrivatePartner: _vm.isInvestorTech,
                        },
                        on: { next_step: _vm.goForward },
                      })
                    : _vm._e(),
                  _vm.step == _vm.alphaSteps.PREFERENCES
                    ? _c("Preferences", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.step == _vm.alphaSteps.PREFERENCES,
                            expression: "step == alphaSteps.PREFERENCES",
                          },
                        ],
                        attrs: {
                          User: _vm.user,
                          PrivatePartner: _vm.isInvestorTech,
                        },
                        on: { next: _vm.goForward },
                      })
                    : _vm._e(),
                  _vm.loading
                    ? _c(
                        VRow,
                        {
                          staticClass: "my-3",
                          staticStyle: { "min-height": "100vh" },
                          attrs: { justify: "center", align: "center" },
                        },
                        [
                          _c(VProgressCircular, {
                            attrs: { indeterminate: "", size: "15" },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.step == _vm.alphaSteps.FINISH && _vm.gs.isMobile()
                ? _c("Finish", {
                    attrs: { User: _vm.user, Portfolios: _vm.portfolioOptions },
                  })
                : _vm._e(),
              _vm.step == _vm.alphaSteps.FINISH && !_vm.gs.isMobile()
                ? _c("FinishDesktop", {
                    attrs: { User: _vm.user, Portfolios: _vm.portfolioOptions },
                  })
                : _vm._e(),
              _vm.gs.isMobile()
                ? _c("SelectPortfolio", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.step == _vm.alphaSteps.SELECTPORTFOLIO,
                        expression: "step == alphaSteps.SELECTPORTFOLIO",
                      },
                    ],
                    attrs: {
                      User: _vm.user,
                      PortfoliosLoading: _vm.portfolioLoading,
                      PortfolioOptions: _vm.portfolioOptions,
                      PrivatePartner: _vm.isInvestorTech,
                    },
                    on: { back: _vm.goBack, next: _vm.goForward },
                  })
                : _vm._e(),
              !_vm.gs.isMobile()
                ? _c("SelelectPortfolioDesktop", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.step == _vm.alphaSteps.SELECTPORTFOLIO,
                        expression: "step == alphaSteps.SELECTPORTFOLIO",
                      },
                    ],
                    attrs: {
                      User: _vm.user,
                      PortfolioOptions: _vm.portfolioOptions,
                      PrivatePartner: _vm.isInvestorTech,
                    },
                    on: { next_step: _vm.goForward },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }