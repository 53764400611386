import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        "justify-content": "center",
        display: "flex",
        "flex-direction": "column",
        padding: "36px",
        "background-color": "var(--dark)",
      },
    },
    [
      _c(VImg, {
        staticStyle: { "max-height": "68px" },
        attrs: {
          src: require("../../../../../../../assets/DXA_Invest-logo.png"),
        },
      }),
      _c("div", { staticStyle: { "margin-top": "50px" } }, [
        _c(
          "span",
          {
            staticStyle: {
              "font-weight": "bold",
              color: "var(--white)",
              "font-size": "31px",
            },
          },
          [_vm._v(" " + _vm._s(_vm.$t("portfolio_created_successfully")) + " ")]
        ),
        _c("br"),
        _c(
          "p",
          {
            staticStyle: {
              color: "var(--white)",
              "font-size": "14px",
              "margin-top": "15px",
              width: "250px",
            },
          },
          [_vm._v(" " + _vm._s(_vm.$t("portfolio_created_message")) + " ")]
        ),
        _c("br"),
        _c(
          "p",
          {
            staticStyle: {
              color: "var(--primary)",
              "font-size": "16px",
              "font-weight": "bold",
            },
          },
          [_vm._v(" " + _vm._s(_vm.$t("next_step") + ":") + " ")]
        ),
        _c(
          "p",
          [
            _c(VIcon, { attrs: { color: "primary" } }, [
              _vm._v("mdi-checkbox-blank-circle"),
            ]),
            _c(
              "span",
              { staticStyle: { color: "var(--white)", "font-weight": "700" } },
              [_vm._v(_vm._s(_vm.$t("your_first_check_in")))]
            ),
          ],
          1
        ),
        _c(
          "p",
          { staticStyle: { color: "var(--white)", "font-weight": "700" } },
          [
            _c(VIcon, { attrs: { color: "white" } }, [
              _vm._v("mdi-calendar-blank-outline"),
            ]),
            _vm._v(" " + _vm._s(_vm.parseDate(_vm.date)) + " "),
          ],
          1
        ),
        _c(
          "p",
          { staticStyle: { color: "var(--white)", "font-weight": "700" } },
          [
            _c(VIcon, { attrs: { color: "white" } }, [
              _vm._v("mdi-timer-outline"),
            ]),
            _vm._v(" " + _vm._s(_vm.formatHour(_vm.hour)) + " "),
          ],
          1
        ),
        _c(
          "p",
          { staticStyle: { color: "var(--white)", "margin-bottom": "36px" } },
          [_vm._v(" " + _vm._s(_vm.$t("invite_send_to_email")) + " ")]
        ),
        _c("br"),
      ]),
      _c(
        "div",
        { staticStyle: { display: "flex", "justify-content": "center" } },
        [
          _c(
            VBtn,
            {
              staticClass: "text-center btn-gradient primary",
              staticStyle: {
                "border-radius": "16px",
                "z-index": "10",
                "box-shadow": "0px 4px 20px var(--primary)",
                width: "292px",
                display: "block",
                height: "47px",
              },
              on: { click: _vm.goToLogin },
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "16px", "font-weight": "bold" } },
                [_vm._v(" " + _vm._s(_vm.$t("Access_dxa")) + " ")]
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }