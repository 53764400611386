import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        display: "flex",
        "flex-direction": "column",
        "min-height": "100vh",
      },
    },
    [
      _c(
        "div",
        { staticStyle: { "align-self": "center" } },
        [
          _c(
            "h2",
            {
              staticClass: "mt-8",
              staticStyle: { "font-weight": "bold", color: "var(--dark)" },
            },
            [_vm._v(" " + _vm._s(_vm.$t("alpha_register_4_header")) + " ")]
          ),
          _c("p", { staticClass: "register-subheader my-3 mb-12" }, [
            _vm._v(" " + _vm._s(_vm.$t("alpha_register_4_subheader")) + " "),
          ]),
          _c(
            "p",
            {
              staticStyle: {
                "font-size": "18px",
                "font-weight": "bold",
                color: "var(--dark)",
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("step_count", { current: 1, last: 6 })) +
                  " "
              ),
            ]
          ),
          _c(VProgressLinear, {
            staticClass: "pa-0 mb-0",
            staticStyle: { width: "1135px" },
            attrs: {
              color: "primary",
              rounded: "",
              height: "6",
              value: (1 * 100) / 6,
            },
          }),
        ],
        1
      ),
      _c(
        VForm,
        {
          ref: "form",
          staticStyle: { display: "flex", "justify-content": "center" },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(
            VCard,
            {
              staticClass: "info-card py-11 px-6 ma-0",
              staticStyle: {
                "border-top-right-radius": "0px",
                "border-bottom-right-radius": "0",
              },
              attrs: { width: "850", color: "white" },
            },
            [
              _c(
                VRow,
                [
                  _c(
                    VCol,
                    { staticClass: "py-0", attrs: { cols: "6" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "field-header",
                          staticStyle: { color: "var(--dark)" },
                        },
                        [_vm._v(_vm._s(_vm.$t("nationality")))]
                      ),
                      _c(VAutocomplete, {
                        staticClass: "mb-4 my-combobox-style",
                        staticStyle: { "z-index": "1000" },
                        attrs: {
                          color: "dark",
                          dense: "",
                          filled: "",
                          rounded: "",
                          rules: [_vm.required],
                          items: _vm.countries,
                          disabled:
                            _vm.User.JuridicalType ==
                            _vm.JuridicalTypeEnum.LegalPerson,
                          "item-text": "name",
                          "return-object": "",
                          "data-test": "Alpha:InvestorInfo:Nationality",
                        },
                        on: {
                          change: function (e) {
                            return _vm.nationality_code_changed(e)
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "selection",
                            fn: function (data) {
                              return [
                                _c("img", {
                                  staticStyle: {
                                    width: "20px",
                                    height: "auto",
                                  },
                                  attrs: { src: data.item.flag, alt: "" },
                                }),
                                _c("span", { staticClass: "mx-2" }, [
                                  _vm._v(_vm._s(data.item.name)),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "item",
                            fn: function (data) {
                              return [
                                _c("img", {
                                  staticStyle: {
                                    width: "20px",
                                    height: "auto",
                                  },
                                  attrs: { src: data.item.flag, alt: "" },
                                }),
                                _c("span", { staticClass: "mx-2" }, [
                                  _vm._v(_vm._s(data.item.name)),
                                ]),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.nationalitySelected,
                          callback: function ($$v) {
                            _vm.nationalitySelected = $$v
                          },
                          expression: "nationalitySelected",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                VRow,
                [
                  _c(
                    VCol,
                    { staticClass: "py-0", attrs: { cols: "6" } },
                    [
                      _c("span", { staticStyle: { color: "var(--dark)" } }, [
                        _vm._v(_vm._s(_vm.$t("document_type"))),
                      ]),
                      _c(
                        VRadioGroup,
                        {
                          staticStyle: { "margin-top": "4px" },
                          attrs: { row: "" },
                          model: {
                            value: _vm.User.DocumentType,
                            callback: function ($$v) {
                              _vm.$set(_vm.User, "DocumentType", $$v)
                            },
                            expression: "User.DocumentType",
                          },
                        },
                        _vm._l(_vm.docsType, function (docType) {
                          return _c(VRadio, {
                            key: docType.value,
                            staticStyle: {
                              display: "flex",
                              "align-itens": "center",
                            },
                            attrs: {
                              value: docType.value,
                              "data-test":
                                "Alpha:InvestorInfo:documentType:" +
                                docType.text,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c(
                                        "span",
                                        { staticClass: "document-type-text" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.translateDocsType(docType)
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    { staticClass: "py-0", attrs: { cols: "6" } },
                    [
                      _c("label", { staticStyle: { color: "var(--dark)" } }, [
                        _vm._v(_vm._s(_vm.$t("document_number"))),
                      ]),
                      _c(VTextField, {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value:
                              _vm.User.DocumentType == _vm.documentTypeEnum.cpf
                                ? "###.###.###-##"
                                : "",
                            expression:
                              "\n              User.DocumentType == documentTypeEnum.cpf\n                ? '###.###.###-##'\n                : ''\n            ",
                          },
                        ],
                        staticClass: "my-input-style",
                        class: {
                          "invalid-field":
                            !_vm.User.SocialNumber || _vm.socialNumberExists,
                        },
                        attrs: {
                          "data-test": "Alpha:InvestorInfo:DocumentNumber",
                          dense: "",
                          rounded: "",
                          disabled:
                            _vm.User.DocumentType == _vm.documentTypeEnum.select
                              ? true
                              : false,
                          placeholder:
                            _vm.User.DocumentType == _vm.documentTypeEnum.cpf
                              ? "000.000.000-00"
                              : "",
                          type: "tel",
                          filled: "",
                          rules: [
                            _vm.required,
                            function (v) {
                              return (
                                (_vm.User.DocumentType ==
                                _vm.documentTypeEnum.cpf
                                  ? _vm.vs.social_number_valid(
                                      _vm.User.SocialNumber
                                    )
                                  : _vm.User.SocialNumber.length >= 7) ||
                                _vm.$t("format_invalid")
                              )
                            },
                          ],
                        },
                        on: {
                          change: function ($event) {
                            return _vm.check_social_number()
                          },
                        },
                        model: {
                          value: _vm.User.SocialNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.User, "SocialNumber", $$v)
                          },
                          expression: "User.SocialNumber",
                        },
                      }),
                      _vm.validatingSocialNumber &&
                      _vm.User.DocumentType == _vm.documentTypeEnum.cpf
                        ? _c(
                            "p",
                            {
                              staticClass: "ma-0",
                              staticStyle: {
                                color: "var(--dark)",
                                "font-size": "11px",
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("validating")) + " ")]
                          )
                        : _vm.socialNumberFormatInvalid &&
                          _vm.User.DocumentType == _vm.documentTypeEnum.cpf
                        ? _c(
                            "p",
                            {
                              staticClass: "ma-0",
                              staticStyle: {
                                color: "red",
                                "font-size": "11px",
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("format_invalid")) + " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.socialNumberExists
                        ? _c(
                            "p",
                            {
                              staticClass: "ma-0",
                              staticStyle: {
                                color: "red",
                                "font-size": "11px",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.User.DocumentType ==
                                      _vm.documentTypeEnum.cpf
                                      ? _vm.$tc("social_number_exists", 1)
                                      : _vm.$tc("social_number_exists", 2)
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    { staticClass: "py-0", attrs: { cols: "6" } },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "mb-0",
                          staticStyle: { color: "var(--dark)" },
                        },
                        [_vm._v(_vm._s(_vm.$t("name")))]
                      ),
                      _c(VTextField, {
                        staticClass: "mb-4 my-input-style",
                        attrs: {
                          "data-test": "Alpha:InvestorInfo:Name",
                          filled: "",
                          dense: "",
                          rounded: "",
                          rules: [
                            function (v) {
                              return (
                                (!!v && _vm.User.Name != null) || _vm.required()
                              )
                            },
                          ],
                          placeholder: _vm.$t("type_your_name"),
                        },
                        model: {
                          value: _vm.User.Name,
                          callback: function ($$v) {
                            _vm.$set(_vm.User, "Name", $$v)
                          },
                          expression: "User.Name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    { staticClass: "py-0", attrs: { cols: "6" } },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "mb-0",
                          staticStyle: { color: "var(--dark)" },
                        },
                        [_vm._v(_vm._s(_vm.$t("last_name")))]
                      ),
                      _c(VTextField, {
                        staticClass: "mb-4 my-input-style",
                        attrs: {
                          "data-test": "Alpha:InvestorInfo:LastName",
                          filled: "",
                          rules: [
                            function (v) {
                              return (
                                (!!v && _vm.User.LastName != null) ||
                                _vm.required()
                              )
                            },
                          ],
                          dense: "",
                          rounded: "",
                          placeholder: _vm.$t("type_your_lastname"),
                        },
                        model: {
                          value: _vm.User.LastName,
                          callback: function ($$v) {
                            _vm.$set(_vm.User, "LastName", $$v)
                          },
                          expression: "User.LastName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                VRow,
                { staticStyle: { "place-content": "space-between" } },
                [
                  _c(
                    VCol,
                    { staticClass: "my-0 py-0 pr-3", attrs: { cols: "6" } },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "mb-0",
                          staticStyle: { color: "var(--dark)" },
                        },
                        [_vm._v(_vm._s(_vm.$t("email")))]
                      ),
                      _c(VTextField, {
                        staticClass: "my-input-style",
                        class: _vm.validatingEmail ? "mb-0" : "mb-4",
                        attrs: {
                          placeholder: _vm.$t("email_placeholder"),
                          filled: "",
                          dense: "",
                          rounded: "",
                          "data-test": "Alpha:InvestorInfo:Email",
                          "hide-details": _vm.validatingEmail,
                          rules: [
                            _vm.required,
                            _vm.email_valid,
                            !_vm.emailFormatInvalid || _vm.$t("format_invalid"),
                            !_vm.emailExists || _vm.$t("user_exists"),
                          ],
                        },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "space", 32, $event.key, [
                                " ",
                                "Spacebar",
                              ])
                            ) {
                              return null
                            }
                            $event.preventDefault()
                          },
                          change: _vm.check_email,
                        },
                        model: {
                          value: _vm.User.Email,
                          callback: function ($$v) {
                            _vm.$set(_vm.User, "Email", $$v)
                          },
                          expression: "User.Email",
                        },
                      }),
                      _vm.validatingEmail
                        ? _c("p", [_vm._v(_vm._s(_vm.$t("validating")))])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    { staticClass: "my-0 py-0 pr-3", attrs: { cols: "2" } },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "mb-0",
                          staticStyle: { color: "var(--dark)" },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("country_code")) + " ")]
                      ),
                      _c(VAutocomplete, {
                        staticClass: "my-combobox-style",
                        attrs: {
                          "data-test": "Alpha:InvestorInfo:CountryCode",
                          color: "dark",
                          dense: "",
                          filled: "",
                          rounded: "",
                          items: _vm.countries,
                          "item-text": "name",
                          rules: [
                            function (v) {
                              return (
                                (!!v && _vm.countryCode != null) ||
                                _vm.required()
                              )
                            },
                          ],
                          "return-object": "",
                        },
                        on: { change: _vm.country_code_changed },
                        scopedSlots: _vm._u([
                          {
                            key: "selection",
                            fn: function (data) {
                              return [
                                _c("img", {
                                  staticStyle: {
                                    width: "20px",
                                    height: "auto",
                                  },
                                  attrs: { src: data.item.flag, alt: "" },
                                }),
                                _c("span", { staticClass: "mx-2" }, [
                                  _vm._v(_vm._s(data.item.callingCodes[0])),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "item",
                            fn: function (data) {
                              return [
                                _c("img", {
                                  staticStyle: {
                                    width: "20px",
                                    height: "auto",
                                  },
                                  attrs: { src: data.item.flag, alt: "" },
                                }),
                                _c("span", { staticClass: "mx-2" }, [
                                  _vm._v(_vm._s(data.item.name)),
                                ]),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.countryCode,
                          callback: function ($$v) {
                            _vm.countryCode = $$v
                          },
                          expression: "countryCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    { staticClass: "py-0", attrs: { cols: "4" } },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "mb-0",
                          staticStyle: { color: "var(--dark)" },
                        },
                        [_vm._v(_vm._s(_vm.$t("celphone")))]
                      ),
                      _c(VTextField, {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value:
                              _vm.countryCode &&
                              _vm.countryCode.callingCodes[0] == "55"
                                ? "(##) #####-####"
                                : "",
                            expression:
                              "\n              countryCode && countryCode.callingCodes[0] == '55'\n                ? '(##) #####-####'\n                : ''\n            ",
                          },
                        ],
                        staticClass: "my-input-style",
                        class: { "invalid-field": !_vm.User.CelPhone },
                        staticStyle: { "border-radius": "4px" },
                        attrs: {
                          "data-test": "Alpha:InvestorInfo:Celphone",
                          dense: "",
                          rounded: "",
                          filled: "",
                          rules: [
                            function (v) {
                              return (
                                (!!v &&
                                  ((_vm.countryCode &&
                                    _vm.countryCode.callingCodes[0] != "55" &&
                                    _vm.User.CelPhone.length >= 8) ||
                                    (_vm.countryCode &&
                                      _vm.countryCode.callingCodes[0] == "55" &&
                                      _vm.User.CelPhone.length >= 14))) ||
                                _vm.required()
                              )
                            },
                          ],
                          type: "tel",
                        },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "space", 32, $event.key, [
                                " ",
                                "Spacebar",
                              ])
                            ) {
                              return null
                            }
                            $event.preventDefault()
                          },
                        },
                        model: {
                          value: _vm.User.CelPhone,
                          callback: function ($$v) {
                            _vm.$set(_vm.User, "CelPhone", $$v)
                          },
                          expression: "User.CelPhone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                VRow,
                { staticStyle: { "place-content": "space-between" } },
                [
                  _c(
                    VCol,
                    { staticClass: "py-0", attrs: { cols: "6" } },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "mb-0",
                          staticStyle: { color: "var(--dark)" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  _vm.User.JuridicalType ==
                                    _vm.JuridicalTypeEnum.NaturalPerson
                                    ? _vm.$t("birth_date")
                                    : _vm.$t("establishment_date")
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(VTextField, {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: ["##/##/####"],
                            expression: "['##/##/####']",
                          },
                        ],
                        staticClass: "my-input-style",
                        class: {
                          "invalid-field":
                            !_vm.User.Birth ||
                            !_vm.isDateValid ||
                            !_vm.isDateAboveLegal,
                        },
                        attrs: {
                          "data-test": "Alpha:InvestorInfo:BirthDate",
                          filled: "",
                          rounded: "",
                          dense: "",
                          rules: [
                            function (v) {
                              return (!!v && v.length == 10) || _vm.required()
                            },
                          ],
                          placeholder: "DD/MM/YYYY",
                          type: "tel",
                        },
                        on: {
                          blur: function ($event) {
                            _vm.User.Birth = _vm.parseDate(_vm.date)
                            _vm.check_date()
                          },
                        },
                        model: {
                          value: _vm.date,
                          callback: function ($$v) {
                            _vm.date = $$v
                          },
                          expression: "date",
                        },
                      }),
                      _vm.date == "" && _vm.fieldsRequired == true
                        ? _c(
                            "p",
                            {
                              staticClass: "mb-4 mt-n4 ml-2",
                              staticStyle: {
                                color: "red",
                                "font-size": "11px",
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("required")) + " ")]
                          )
                        : _vm._e(),
                      !_vm.isDateValid
                        ? _c(
                            "p",
                            {
                              staticClass: "mb-4 mt-n3 ml-3",
                              staticStyle: {
                                color: "red",
                                "font-size": "11px",
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("valid_date")) + " ")]
                          )
                        : !_vm.isDateAboveLegal
                        ? _c(
                            "p",
                            {
                              staticClass: "mb-4 mt-n4 ml-2",
                              staticStyle: {
                                color: "red",
                                "font-size": "11px",
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("min_18")) + " ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                VRow,
                [
                  _c(
                    VCol,
                    { staticClass: "py-0 pr-3", attrs: { cols: "6" } },
                    [
                      _c("span", { staticStyle: { color: "var(--dark)" } }, [
                        _vm._v(_vm._s(_vm.$t("password"))),
                      ]),
                      _c(VTextField, {
                        ref: "password",
                        staticClass: "my-input-style",
                        attrs: {
                          "data-test": "Alpha:InvestorInfo:Password",
                          dense: "",
                          rounded: "",
                          rules: [
                            function (v) {
                              return (
                                (!!v && _vm.verifyAllPasswordRequirements) ||
                                _vm.required()
                              )
                            },
                          ],
                          type: _vm.show1 ? "text" : "password",
                          "append-icon": _vm.show1 ? "mdi-eye" : "mdi-eye-off",
                          small: "",
                          filled: "",
                        },
                        on: {
                          keyup: _vm.verifyRequirements,
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "space", 32, $event.key, [
                                " ",
                                "Spacebar",
                              ])
                            ) {
                              return null
                            }
                            $event.preventDefault()
                          },
                          "click:append": function ($event) {
                            _vm.show1 = !_vm.show1
                          },
                        },
                        model: {
                          value: _vm.User.UserPassword,
                          callback: function ($$v) {
                            _vm.$set(_vm.User, "UserPassword", $$v)
                          },
                          expression: "User.UserPassword",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    { staticClass: "py-0", attrs: { cols: "6" } },
                    [
                      _c("span", { staticStyle: { color: "var(--dark)" } }, [
                        _vm._v(_vm._s(_vm.$t("password_confirmation"))),
                      ]),
                      _c(VTextField, {
                        staticClass: "my-input-style",
                        attrs: {
                          "data-test":
                            "Alpha:InvestorInfo:PasswordConfirmation",
                          rules: [_vm.required].concat(
                            _vm.passwordConfirmationRule
                          ),
                          dense: "",
                          rounded: "",
                          type: _vm.show1 ? "text" : "password",
                          "append-icon": _vm.show1 ? "mdi-eye" : "mdi-eye-off",
                          filled: "",
                        },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "space", 32, $event.key, [
                                " ",
                                "Spacebar",
                              ])
                            ) {
                              return null
                            }
                            $event.preventDefault()
                          },
                          "click:append": function ($event) {
                            _vm.show1 = !_vm.show1
                          },
                        },
                        model: {
                          value: _vm.passwordConfirmation,
                          callback: function ($$v) {
                            _vm.passwordConfirmation = $$v
                          },
                          expression: "passwordConfirmation",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                VRow,
                [
                  _c(
                    VCol,
                    { attrs: { cols: "12" } },
                    [
                      _c("ValidateStrengthPassword", {
                        attrs: { userPassword: _vm.User.UserPassword },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("PasswordChecklist", {
                ref: "passwordChecklist",
                attrs: { password: _vm.User.UserPassword },
              }),
              _c("DxaButton", {
                staticClass: "mt-10 mb-8",
                attrs: {
                  "data-test": "Alpha:InvestorInfo:Submit",
                  title: _vm.$t("continue"),
                },
                on: { function: _vm.checkForm },
              }),
            ],
            1
          ),
          _c(
            VCard,
            {
              staticClass: "ma-0 pa-5",
              staticStyle: {
                display: "flex",
                "flex-direction": "column",
                "justify-content": "space-between",
                "background-color": "var(--dark)",
                "border-radius": "0px 12px 12px 0px",
                "box-shadow": "0px 7px 20px rgba(45, 48, 56, 0.1) !important",
              },
            },
            [
              _c(
                "div",
                { staticStyle: { display: "flex", "justify-content": "end" } },
                [
                  _c("img", {
                    staticStyle: {
                      "margin-right": "15px",
                      "margin-top": "15px",
                      "max-width": "50px",
                      opacity: "0",
                    },
                    attrs: {
                      src: require("../../../../../../../../assets/dxa-x.png"),
                      alt: "",
                    },
                  }),
                ]
              ),
              _c("div", [
                _c("img", {
                  staticStyle: {
                    "max-height": "256px",
                    transform: "scaleX(-1)",
                  },
                  attrs: {
                    src: require("../../../../../../../../assets/dxa-background-welcome-page3(1).png"),
                    alt: "",
                  },
                }),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        VDialog,
        {
          staticStyle: { "z-index": "100000" },
          attrs: { persistent: "", "max-width": "450" },
          model: {
            value: _vm.socialNumberDialog,
            callback: function ($$v) {
              _vm.socialNumberDialog = $$v
            },
            expression: "socialNumberDialog",
          },
        },
        [
          _c(
            VCard,
            {
              staticClass: "dialog-bg",
              staticStyle: {
                "border-radius": "10px",
                "background-color": "var(--white) !important",
              },
            },
            [
              _c(
                VRow,
                {
                  staticClass: "pr-6",
                  attrs: { justify: "end", align: "center" },
                },
                [
                  _c(
                    VIcon,
                    {
                      attrs: { color: "black" },
                      on: {
                        click: function ($event) {
                          _vm.socialNumberDialog = false
                        },
                      },
                    },
                    [_vm._v("mdi-close")]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "icon-alert" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/alert-icon.svg"),
                    alt: "alert",
                  },
                }),
              ]),
              _c(
                "h2",
                {
                  staticClass: "pt-3 px-4 pb-3 text-center",
                  staticStyle: { color: "var(--dark)" },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("social_number_already_exists")) + " "
                  ),
                ]
              ),
              _c(
                "p",
                {
                  staticClass: "px-12 text-center",
                  staticStyle: {
                    color: "var(--dark)",
                    "max-width": "400px",
                    "margin-left": "auto",
                    "margin-right": "auto",
                    "letter-spacing": "0.25px",
                    "font-size": "16px",
                    "line-height": "20px",
                    "font-family": "'Source Sans Pro'",
                  },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("if_social_number_is_yours")) + " "
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "alert-create-box" },
                [
                  _c(
                    VBtn,
                    {
                      staticClass: "mb-5",
                      staticStyle: {
                        "border-style": "groove",
                        "border-color": "var(--primary)",
                        "background-color": "var(--white)",
                        color: "var(--primary)",
                        "font-size": "16px",
                        "border-radius": "8px",
                      },
                      attrs: { outlined: "", height: "35", width: "124" },
                      on: {
                        click: function ($event) {
                          _vm.socialNumberDialog = false
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("cancel")))]
                  ),
                  _c(
                    VBtn,
                    {
                      staticClass: "mb-5",
                      staticStyle: {
                        "border-radius": "8px",
                        "background-color": "var(--primary)",
                        color: "var(--white)",
                        border: "none",
                        "font-size": "16px",
                      },
                      attrs: { outlined: "", height: "35", width: "124" },
                      on: { click: _vm.goToLogin },
                    },
                    [_vm._v(_vm._s(_vm.$t("login_txt")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }