import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pa-5" },
    [
      _c(
        VForm,
        {
          ref: "form",
          attrs: { "lazy-validation": _vm.lazy },
          on: {
            submit: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.verifyCode($event)
            },
          },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _vm.confirmData == false
            ? _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "flex-direction": "column",
                    "align-items": "center",
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        VDialog,
                        {
                          attrs: { width: "300" },
                          model: {
                            value: _vm.dialog,
                            callback: function ($$v) {
                              _vm.dialog = $$v
                            },
                            expression: "dialog",
                          },
                        },
                        [
                          _c(
                            VCard,
                            {
                              staticStyle: {
                                "background-color": "var(--white)",
                                display: "flex",
                                "flex-direction": "column",
                                "justify-content": "center",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "end",
                                    float: "right",
                                  },
                                },
                                [
                                  _c(
                                    VBtn,
                                    {
                                      staticClass: "pa-5 pb-0",
                                      staticStyle: {
                                        "background-color": "var(--white)",
                                        display: "flex",
                                      },
                                      attrs: {
                                        width: "50px",
                                        "x-small": "",
                                        elevation: "0",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.goToPipeline()
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        VIcon,
                                        { attrs: { color: "black" } },
                                        [_vm._v("mdi-close")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                VIcon,
                                {
                                  staticClass: "pb-2 pt-0",
                                  staticStyle: { "border-radius": "20px" },
                                  attrs: { "x-large": "", color: "primary" },
                                },
                                [_vm._v("mdi-check-circle")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "py-0 px-4 validated-account-header",
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "column",
                                    "text-align": "center",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("validated_account_header"))
                                  ),
                                ]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass: "px-5 validated-account-text",
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "column",
                                    "text-align": "center",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("validated_account_text")) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                VBtn,
                                {
                                  staticClass: "px-5 mx-5 my-5",
                                  attrs: {
                                    "data-test":
                                      "Alpha:ConfirmData:BtnContinue",
                                    id: "button-finish-confirm",
                                    outlined: "",
                                    color: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goToPipeline()
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("continue")) + " ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(VRow, { staticClass: "ma-0" }, [
                        _c(
                          "p",
                          {
                            staticClass: "mx-auto",
                            staticStyle: {
                              "font-size": "1.5rem",
                              color: "var(--dark)",
                            },
                          },
                          [
                            _c("strong", [
                              _vm._v(_vm._s(_vm.$t("confirm_data_alpha"))),
                            ]),
                          ]
                        ),
                        _c("span", {
                          staticClass: "confirm-data-subtitle",
                          staticStyle: { "text-align": "center" },
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t("message_sent_email_and_cellphone", {
                                email: _vm.email_msg,
                                cellphone: _vm.number_msg,
                              })
                            ),
                          },
                        }),
                      ]),
                      _c(
                        VRow,
                        { staticClass: "ma-0 mt-12" },
                        [
                          _c(
                            VCol,
                            { staticClass: "pa-0", attrs: { cols: "12" } },
                            [
                              _c(
                                "span",
                                { staticClass: "confirm-data-label" },
                                [_vm._v(_vm._s(_vm.$t("pin")))]
                              ),
                              _c(VTextField, {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: ["####"],
                                    expression: "['####']",
                                  },
                                ],
                                attrs: {
                                  "background-color": "white",
                                  "data-test": "Alpha:ConfirmData:ConfirmPin",
                                  "hide-details": "",
                                  dense: "",
                                  rules: [_vm.required],
                                  outlined: "",
                                },
                                model: {
                                  value: _vm.code,
                                  callback: function ($$v) {
                                    _vm.code = $$v
                                  },
                                  expression: "code",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            VCol,
                            {
                              staticClass: "pt-0 pr-0 pl-0",
                              staticStyle: {
                                display: "flex",
                                "text-decoration": "none",
                              },
                              attrs: { cols: "6" },
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "link-resend-code",
                                  class: [_vm.isDisabled ? "disabled" : ""],
                                  on: { click: _vm.resendCode },
                                },
                                [_vm._v(_vm._s(_vm.$t("re_send_code")))]
                              ),
                            ]
                          ),
                          _c(
                            VCol,
                            {
                              staticClass: "px-0 pt-1",
                              staticStyle: {
                                display: "flex",
                                "justify-content": "end",
                              },
                            },
                            [
                              _vm.showResendMessage
                                ? _c("a", { staticClass: "try-again-text" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("try_again_in", {
                                          countDown: _vm.secondsToRetry,
                                        })
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(VRow, {
                        staticClass: "mt-1",
                        staticStyle: { "max-width": "275px", margin: "0 auto" },
                        attrs: { justify: "center" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { "margin-top": "150px", width: "100%" } },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "primary ma-0 button-confirm-data",
                          attrs: {
                            "data-test": "Alpha:ConfirmData:ConfirmDataAlpha",
                            type: "submit",
                            loading: _vm.loading,
                          },
                        },
                        [_c("h1", [_vm._v(_vm._s(_vm.$t("confirm")))])]
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm.confirmData
            ? _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "flex-direction": "column",
                    "justify-content": "center",
                  },
                },
                [
                  _c(
                    "h1",
                    {
                      staticClass: "mb-4 confirm-data-title",
                      staticStyle: { "text-align": "center" },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("confirm_data_alpha")) + " ")]
                  ),
                  _c(
                    "p",
                    {
                      staticClass: "confirm-data-subtitle",
                      staticStyle: { "text-align": "center" },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("confirm_data_new_text")) + " "
                      ),
                    ]
                  ),
                  _c(
                    VRow,
                    { staticClass: "ma-0" },
                    [
                      _c(
                        VCol,
                        { attrs: { cols: "12" } },
                        [
                          _c("span", { staticClass: "confirm-data-label" }, [
                            _vm._v(_vm._s(_vm.$t("single_celphone"))),
                          ]),
                          _c(VTextField, {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value:
                                  _vm.User.CountryCode &&
                                  _vm.User.CountryCode.callingCodes[0] == "55"
                                    ? "+55 (##) #####-####"
                                    : "+" +
                                      _vm.User.CountryCode.callingCodes[0] +
                                      " ##########",
                                expression:
                                  "\n              User.CountryCode && User.CountryCode.callingCodes[0] == '55'\n                ? '+55 (##) #####-####'\n                : `+${User.CountryCode.callingCodes[0]} ##########`\n            ",
                              },
                            ],
                            attrs: {
                              "background-color": "white",
                              dense: "",
                              outlined: "",
                              rules: [_vm.required],
                              "hide-details": "",
                            },
                            on: {
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "space",
                                    32,
                                    $event.key,
                                    [" ", "Spacebar"]
                                  )
                                ) {
                                  return null
                                }
                                $event.preventDefault()
                              },
                            },
                            model: {
                              value: _vm.User.CelPhone,
                              callback: function ($$v) {
                                _vm.$set(_vm.User, "CelPhone", $$v)
                              },
                              expression: "User.CelPhone",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { cols: "12" } },
                        [
                          _c("span", { staticClass: "confirm-data-label" }, [
                            _vm._v(_vm._s(_vm.$t("email"))),
                          ]),
                          _c(VTextField, {
                            attrs: {
                              color: "primary",
                              "background-color": "white",
                              dense: "",
                              outlined: "",
                              rules: [_vm.required],
                            },
                            on: {
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "space",
                                    32,
                                    $event.key,
                                    [" ", "Spacebar"]
                                  )
                                ) {
                                  return null
                                }
                                $event.preventDefault()
                              },
                            },
                            model: {
                              value: _vm.User.Email,
                              callback: function ($$v) {
                                _vm.$set(_vm.User, "Email", $$v)
                              },
                              expression: "User.Email",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    VRow,
                    { staticClass: "ma-0" },
                    [
                      _c(
                        VCol,
                        [
                          _c(
                            VBtn,
                            {
                              staticClass: "ma-0 primary button-confirm-data",
                              class: _vm.loading ? "disabled" : "",
                              staticStyle: { "border-radius": "15px" },
                              attrs: {
                                "data-test": "Alpha:ConfirmData:NextStep",
                                loading: _vm.loading,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.createUserFromPartner()
                                },
                              },
                            },
                            [_c("h1", [_vm._v(_vm._s(this.$t("confirm")))])]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm.alert
        ? _c(VAlert, { staticClass: "ma-2", attrs: { type: "error" } }, [
            _vm._v(" " + _vm._s(_vm.alert) + " "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }