var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-content" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("div", { staticClass: "header-container" }, [
          _c("span", { staticClass: "header-title" }, [
            _vm._v(_vm._s(_vm.$t("portfolio_preferences"))),
          ]),
          _c("span", { staticClass: "header-subtitle" }, [
            _vm._v(_vm._s(_vm.$t("preferences_subtitle"))),
          ]),
        ]),
        _c("div", { staticClass: "step-container" }, [
          _c("span", { staticClass: "step" }, [
            _vm._v(
              _vm._s(
                _vm.$t("alpha_register_step_count", { step: 3, totalSteps: 6 })
              )
            ),
          ]),
        ]),
        _c("div", { staticClass: "progress" }),
        _c("UserPreferences", {
          attrs: {
            PrivatePartner: _vm.PrivatePartner,
            editable: true,
            btnText: "alpha_register_create_portfolio",
            User: _vm.User,
            userType: _vm.userTypeEnum.Investor,
          },
          on: { btnConfirm: _vm.nextStep },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }