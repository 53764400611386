import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "select-porfolio-bg",
      staticStyle: { "margin-bottom": "0px" },
    },
    [
      _c("div", { staticClass: "select-portfolio-grid" }, [
        _c(
          "div",
          { staticStyle: { "margin-right": "95px" } },
          [
            _vm.PrivatePartner
              ? _c("h2", { staticClass: "select-portfolio-title" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("alpha_register_2_header_tech")) + " "
                  ),
                ])
              : _c("h2", { staticClass: "select-portfolio-title" }, [
                  _vm._v(" " + _vm._s(_vm.$t("alpha_register_2_header")) + " "),
                ]),
            _c("h3", { staticClass: "select-portfolio-subtitle" }, [
              _vm._v(" " + _vm._s(_vm.$t("alpha_register_2_subheader")) + " "),
            ]),
            _c("h3", { staticClass: "step-count-title" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("step_count", { current: 2, last: 6 })) +
                  " "
              ),
            ]),
            _c(VProgressLinear, {
              staticStyle: { width: "200px" },
              attrs: {
                color: "primary",
                rounded: "",
                height: "6",
                value: "200",
              },
            }),
            _c("div", { staticClass: "select-portfolio-content" }, [
              _c("ul", { staticClass: "select-portfolio-content-list" }, [
                _c("div", [
                  _c(
                    "li",
                    [
                      _c(VIcon, { attrs: { small: "", color: "primary" } }, [
                        _vm._v("mdi-check"),
                      ]),
                      _c(
                        "p",
                        { staticClass: "select-portfolio-content-list-item" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("alpha_register_suggestions_allocations")
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm.PrivatePartner
                    ? _c(
                        "li",
                        [
                          _c(
                            VIcon,
                            { attrs: { small: "", color: "primary" } },
                            [_vm._v("mdi-check")]
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "select-portfolio-content-list-item",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("regulated_manager_cvm")) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.PrivatePartner
                    ? _c(
                        "li",
                        [
                          _c(
                            VIcon,
                            { attrs: { small: "", color: "primary" } },
                            [_vm._v("mdi-check")]
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "select-portfolio-content-list-item",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("exclusive_opportunities")) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.PrivatePartner
                    ? _c(
                        "li",
                        [
                          _c(
                            VIcon,
                            { attrs: { small: "", color: "primary" } },
                            [_vm._v("mdi-check")]
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "select-portfolio-content-list-item",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("alpha_register_plan_investment")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.PrivatePartner
                    ? _c(
                        "li",
                        [
                          _c(
                            VIcon,
                            { attrs: { small: "", color: "primary" } },
                            [_vm._v("mdi-check")]
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "select-portfolio-content-list-item",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "alpha_register_exclusive_management"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _c("div", [
                  !_vm.PrivatePartner
                    ? _c(
                        "li",
                        [
                          _c(
                            VIcon,
                            { attrs: { small: "", color: "primary" } },
                            [_vm._v("mdi-check")]
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "select-portfolio-content-list-item",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("alpha_register_active_governance")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.PrivatePartner
                    ? _c(
                        "li",
                        [
                          _c(
                            VIcon,
                            { attrs: { small: "", color: "primary" } },
                            [_vm._v("mdi-check")]
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "select-portfolio-content-list-item",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("alpha_register_start_investment")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "items-center" },
                [
                  _c("DxaButton", {
                    staticClass: "mt-10 button-next-step",
                    attrs: {
                      "data-test": "Alpha:SelectPortfolio:Next",
                      title: _vm.$t("alpha_register_create_portfolio"),
                    },
                    on: { function: _vm.nextStep },
                  }),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ]),
      _c(VImg, {
        staticClass: "dxa-background-x",
        attrs: {
          "max-width": "45%",
          src: require("../../../../../../../../assets/dxa-background-welcome-page3.png"),
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }