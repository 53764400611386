import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "flexing d-flex justify-end",
      staticStyle: {
        "margin-bottom": "0px",
        height: "100vh",
        "padding-top": "75px",
        "background-color": "var(--dark)",
      },
    },
    [
      _c(
        "div",
        { staticClass: "nav-bar" },
        [
          _c(
            VCard,
            {
              staticClass: "ma-0 pa-0",
              staticStyle: {
                position: "absolute",
                top: "10px",
                left: "10%",
                display: "flex",
                "flex-direction": "row",
                "background-color": "var(--dark)",
                "box-shadow": "none !important",
              },
              attrs: { elevation: "0" },
            },
            [
              _c(VImg, {
                staticClass: "mx-2",
                staticStyle: { width: "100px !important" },
                attrs: { src: require("@/assets/dxa-white.png") },
              }),
              _c(
                "span",
                {
                  staticStyle: { color: "var(--primary)", "font-size": "28px" },
                },
                [_vm._v("+")]
              ),
              _vm.partnerLogo
                ? _c(VImg, {
                    staticClass: "mx-2 m",
                    staticStyle: { width: "110px !important" },
                    attrs: { src: _vm.gs.get_photo_path(_vm.partnerLogo) },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "max-width": "420px", "padding-right": "10%" } },
        [
          _c(
            "span",
            {
              staticStyle: {
                "font-weight": "bold",
                color: "var(--white)",
                "font-size": "31px",
              },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("portfolio_created_successfully")) + " "
              ),
            ]
          ),
          _c("br"),
          _c(
            "p",
            {
              staticStyle: {
                color: "var(--white)",
                "font-size": "14px",
                "margin-top": "15px",
                width: "290px",
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("portfolio_created_message")) + " ")]
          ),
          _c("br"),
          _c(
            "p",
            {
              staticStyle: {
                color: "var(--primary)",
                "font-size": "16px",
                "font-weight": "bold",
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("next_step") + ":") + " ")]
          ),
          _c(
            "p",
            [
              _c(VIcon, { attrs: { color: "primary" } }, [
                _vm._v("mdi-checkbox-blank-circle"),
              ]),
              _c(
                "span",
                {
                  staticStyle: { color: "var(--white)", "font-weight": "700" },
                },
                [_vm._v(_vm._s(_vm.$t("your_first_check_in")))]
              ),
            ],
            1
          ),
          _c(
            "p",
            { staticStyle: { color: "var(--white)", "font-weight": "700" } },
            [
              _c(VIcon, { attrs: { color: "white" } }, [
                _vm._v("mdi-calendar-blank-outline"),
              ]),
              _vm._v(" " + _vm._s(_vm.parseDate(_vm.date)) + " "),
            ],
            1
          ),
          _c(
            "p",
            { staticStyle: { color: "var(--white)", "font-weight": "700" } },
            [
              _c(VIcon, { attrs: { color: "white" } }, [
                _vm._v("mdi-timer-outline"),
              ]),
              _vm._v(" " + _vm._s(_vm.formatHour(_vm.hour)) + " "),
            ],
            1
          ),
          _c(
            "p",
            { staticStyle: { color: "var(--white)", "margin-bottom": "36px" } },
            [_vm._v(" " + _vm._s(_vm.$t("invite_send_to_email")) + " ")]
          ),
          _c("DxaButton", {
            staticStyle: { width: "250px" },
            attrs: { title: _vm.$t("Access_dxa") },
            on: { function: _vm.goToLogin },
          }),
          _c("br"),
        ],
        1
      ),
      _c(VImg, {
        staticStyle: {
          position: "absolute",
          top: "0",
          left: "0",
          "z-index": "1 !important",
        },
        attrs: {
          "max-width": "85%",
          src: require("../../../../../../../../assets/dxa-background-welcome-page2.png"),
        },
      }),
      _c(VImg, {
        staticStyle: { position: "absolute", bottom: "25px", left: "25px" },
        attrs: {
          "max-width": "45%",
          src: require("../../../../../../../../assets/dxa-background-welcome-page3.png"),
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }