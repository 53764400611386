import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VForm,
    {
      ref: "form",
      staticClass: "mt-3 pa-5",
      class: !_vm.isMobile ? "DesktopCard" : "",
      attrs: { "lazy-validation": "" },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _vm.$route.name != "AlphaRegisterClientDXA"
        ? _c(
            "div",
            [
              _c(
                "span",
                {
                  staticClass: "field-header",
                  staticStyle: { color: "var(--dark)" },
                },
                [_vm._v(_vm._s(_vm.$t("nationality")))]
              ),
              _c(VCombobox, {
                staticClass: "mb-4 my-combobox-style",
                attrs: {
                  color: "black",
                  dense: "",
                  filled: "",
                  rounded: "",
                  rules: [_vm.required],
                  items: _vm.countries,
                  disabled:
                    _vm.User.JuridicalType == _vm.JuridicalTypeEnum.LegalPerson,
                  "item-text": "name",
                  "return-object": "",
                  "data-test": "Alpha:InvestorInfo:Nationality",
                },
                on: {
                  change: function (e) {
                    return _vm.nationality_code_changed(e)
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "selection",
                      fn: function (data) {
                        return [
                          _c("img", {
                            staticStyle: { width: "20px", height: "auto" },
                            attrs: { src: data.item.flag, alt: "" },
                          }),
                          _c("span", { staticClass: "mx-2" }, [
                            _vm._v(_vm._s(data.item.name)),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "item",
                      fn: function (data) {
                        return [
                          _c("img", {
                            staticStyle: { width: "20px", height: "auto" },
                            attrs: { src: data.item.flag, alt: "" },
                          }),
                          _c("span", { staticClass: "mx-2" }, [
                            _vm._v(_vm._s(data.item.name)),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3149773820
                ),
                model: {
                  value: _vm.nationalitySelected,
                  callback: function ($$v) {
                    _vm.nationalitySelected = $$v
                  },
                  expression: "nationalitySelected",
                },
              }),
              _c(
                "span",
                {
                  staticClass: "field-header",
                  staticStyle: { color: "var(--dark)" },
                },
                [_vm._v(_vm._s(_vm.$t("document_type")))]
              ),
              _c(
                VRadioGroup,
                {
                  staticStyle: { "margin-top": "4px" },
                  attrs: { row: "" },
                  model: {
                    value: _vm.User.DocumentType,
                    callback: function ($$v) {
                      _vm.$set(_vm.User, "DocumentType", $$v)
                    },
                    expression: "User.DocumentType",
                  },
                },
                _vm._l(_vm.docsType, function (docType) {
                  return _c(VRadio, {
                    key: docType.value,
                    staticStyle: { display: "flex", "align-itens": "center" },
                    attrs: {
                      value: docType.value,
                      "data-test":
                        "Alpha:InvestorInfo:documentType:" + docType.text,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c(
                                "span",
                                { staticClass: "document-type-text" },
                                [_vm._v(_vm._s(_vm.translateDocsType(docType)))]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  })
                }),
                1
              ),
              _c(
                "label",
                {
                  staticClass: "field-header",
                  staticStyle: { color: "var(--dark)" },
                },
                [_vm._v(_vm._s(_vm.$t("document_number")))]
              ),
              _c(VTextField, {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value:
                      _vm.User.DocumentType == _vm.documentTypeEnum.cpf
                        ? "###.###.###-##"
                        : "",
                    expression:
                      "\n        User.DocumentType == documentTypeEnum.cpf ? '###.###.###-##' : ''\n      ",
                  },
                ],
                staticClass: "my-input-style",
                class: {
                  "invalid-field":
                    !_vm.User.SocialNumber || _vm.socialNumberExists,
                },
                attrs: {
                  "data-test": "Alpha:InvestorInfo:DocumentNumber",
                  dense: "",
                  rounded: "",
                  placeholder:
                    _vm.User.DocumentType == _vm.documentTypeEnum.cpf
                      ? "000.000.000-00"
                      : "",
                  type: "tel",
                  rules: [
                    _vm.required,
                    function (v) {
                      return (
                        (_vm.User.DocumentType == _vm.documentTypeEnum.cpf
                          ? _vm.vs.social_number_valid(_vm.User.SocialNumber)
                          : _vm.User.SocialNumber.length >= 7) ||
                        _vm.$t("format_invalid")
                      )
                    },
                  ],
                  disabled:
                    _vm.User.DocumentType == _vm.documentTypeEnum.select
                      ? true
                      : false,
                  filled: "",
                },
                on: {
                  change: function ($event) {
                    return _vm.check_social_number()
                  },
                },
                model: {
                  value: _vm.User.SocialNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.User, "SocialNumber", $$v)
                  },
                  expression: "User.SocialNumber",
                },
              }),
              _vm.socialNumberExists
                ? _c(
                    "p",
                    {
                      staticClass: "mb-4",
                      staticStyle: { color: "red", "font-size": "11px" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.User.DocumentType == _vm.documentTypeEnum.cpf
                              ? _vm.$tc("social_number_exists", 1)
                              : _vm.$tc("social_number_exists", 2)
                          ) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "p",
                {
                  staticClass: "field-header mb-0",
                  staticStyle: { color: "var(--dark)" },
                },
                [_vm._v(" " + _vm._s(_vm.$t("name")) + " ")]
              ),
              _c(VTextField, {
                staticClass: "mb-4 my-input-style",
                attrs: {
                  "data-test": "Alpha:InvestorInfo:Name",
                  filled: "",
                  rounded: "",
                  rules: [
                    function (v) {
                      return (!!v && _vm.User.Name != null) || _vm.required()
                    },
                  ],
                  dense: "",
                  placeholder: _vm.$t("type_your_name"),
                },
                model: {
                  value: _vm.User.Name,
                  callback: function ($$v) {
                    _vm.$set(_vm.User, "Name", $$v)
                  },
                  expression: "User.Name",
                },
              }),
              _c(
                "p",
                {
                  staticClass: "field-header mb-0",
                  staticStyle: { color: "var(--dark)" },
                },
                [_vm._v(" " + _vm._s(_vm.$t("last_name")) + " ")]
              ),
              _c(VTextField, {
                staticClass: "mb-4 my-input-style",
                attrs: {
                  placeholder: _vm.$t("type_your_lastname"),
                  "data-test": "Alpha:InvestorInfo:LastName",
                  filled: "",
                  rounded: "",
                  rules: [
                    function (v) {
                      return (
                        (!!v && _vm.User.LastName != null) || _vm.required()
                      )
                    },
                  ],
                  dense: "",
                },
                model: {
                  value: _vm.User.LastName,
                  callback: function ($$v) {
                    _vm.$set(_vm.User, "LastName", $$v)
                  },
                  expression: "User.LastName",
                },
              }),
              _c(
                "p",
                {
                  staticClass: "field-header mb-0",
                  staticStyle: { color: "var(--dark)" },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          _vm.User.JuridicalType ==
                            _vm.JuridicalTypeEnum.NaturalPerson
                            ? _vm.$t("birth_date")
                            : _vm.$t("establishment_date")
                        )
                      ) +
                      " "
                  ),
                ]
              ),
              _c(VTextField, {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: ["##/##/####"],
                    expression: "['##/##/####']",
                  },
                ],
                staticClass: "my-input-style",
                class: {
                  "invalid-field":
                    !_vm.User.Birth ||
                    !_vm.isDateValid ||
                    !_vm.isDateAboveLegal,
                },
                attrs: {
                  "data-test": "Alpha:InvestorInfo:BirthDate",
                  filled: "",
                  rounded: "",
                  dense: "",
                  rules: [
                    function (v) {
                      return (!!v && v.length == 10) || _vm.required()
                    },
                  ],
                  placeholder: "DD/MM/YYYY",
                  type: "tel",
                },
                on: {
                  blur: function ($event) {
                    _vm.User.Birth = _vm.parseDate(_vm.date)
                    _vm.check_date()
                  },
                },
                model: {
                  value: _vm.date,
                  callback: function ($$v) {
                    _vm.date = $$v
                  },
                  expression: "date",
                },
              }),
              _vm.date == "" && _vm.fieldsRequired == true
                ? _c(
                    "p",
                    {
                      staticClass: "mb-4 mt-n4 ml-2",
                      staticStyle: { color: "red", "font-size": "11px" },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("required")) + " ")]
                  )
                : _vm._e(),
              !_vm.isDateValid
                ? _c(
                    "p",
                    {
                      staticClass: "mb-4 mt-n3 ml-3",
                      staticStyle: { color: "red", "font-size": "11px" },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("valid_date")) + " ")]
                  )
                : !_vm.isDateAboveLegal
                ? _c(
                    "p",
                    {
                      staticClass: "mb-4 mt-n4 ml-2",
                      staticStyle: { color: "red", "font-size": "11px" },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("min_18")) + " ")]
                  )
                : _vm._e(),
              _vm.validatingSocialNumber &&
              _vm.User.DocumentType == _vm.documentTypeEnum.cpf
                ? _c(
                    "p",
                    {
                      staticClass: "ma-0",
                      staticStyle: {
                        color: "var(--dark)",
                        "font-size": "11px",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("validating")) + " ")]
                  )
                : _vm.socialNumberFormatInvalid &&
                  _vm.User.DocumentType == _vm.documentTypeEnum.cpf
                ? _c(
                    "p",
                    {
                      staticClass: "ma-0",
                      staticStyle: { color: "red", "font-size": "11px" },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("format_invalid")) + " ")]
                  )
                : _vm._e(),
              _c(
                "p",
                {
                  staticClass: "field-header mb-0",
                  staticStyle: { color: "var(--dark)" },
                },
                [_vm._v(" " + _vm._s(_vm.$t("email")) + " ")]
              ),
              _c(VTextField, {
                staticClass: "my-input-style",
                class: _vm.validatingEmail ? "mb-0" : "mb-4",
                attrs: {
                  placeholder: _vm.$t("email_placeholder"),
                  "data-test": "Alpha:InvestorInfo:Email",
                  filled: "",
                  rounded: "",
                  dense: "",
                  "hide-details": _vm.validatingEmail,
                  rules: [
                    _vm.required,
                    _vm.email_valid,
                    !_vm.emailFormatInvalid || _vm.$t("format_invalid"),
                    !_vm.emailExists || _vm.$t("user_exists"),
                  ],
                },
                on: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "space", 32, $event.key, [
                        " ",
                        "Spacebar",
                      ])
                    ) {
                      return null
                    }
                    $event.preventDefault()
                  },
                  change: _vm.check_email,
                },
                model: {
                  value: _vm.User.Email,
                  callback: function ($$v) {
                    _vm.$set(_vm.User, "Email", $$v)
                  },
                  expression: "User.Email",
                },
              }),
              _vm.validatingEmail
                ? _c("p", [_vm._v(_vm._s(_vm.$t("validating")))])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        VRow,
        { attrs: { "no-gutters": "" } },
        [
          _c(
            VCol,
            { staticClass: "my-0 py-0 pr-3", attrs: { cols: "4", md: "4" } },
            [
              _c(
                "span",
                {
                  staticClass: "field-header",
                  staticStyle: { color: "var(--dark)" },
                },
                [_vm._v(_vm._s(_vm.$t("country_code")))]
              ),
            ]
          ),
          _c(
            VCol,
            { staticClass: "my-0 py-0", attrs: { cols: "8", md: "8" } },
            [
              _c(
                "span",
                {
                  staticClass: "field-header",
                  staticStyle: { color: "var(--dark)" },
                },
                [_vm._v(_vm._s(_vm.$t("celphone")))]
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        VRow,
        { staticClass: "mb-4", attrs: { "no-gutters": "" } },
        [
          _c(
            VCol,
            { staticClass: "my-0 py-0 pr-3", attrs: { cols: "4", md: "4" } },
            [
              _c(VAutocomplete, {
                staticClass: "my-combobox-style",
                attrs: {
                  color: "black",
                  dense: "",
                  filled: "",
                  rounded: "",
                  rules: [
                    function (v) {
                      return (!!v && _vm.countryCode != null) || _vm.required()
                    },
                  ],
                  items: _vm.countries,
                  "item-text": "name",
                  "return-object": "",
                  "data-test": "Alpha:InvestorInfo:CountryCode",
                },
                on: { change: _vm.country_code_changed },
                scopedSlots: _vm._u([
                  {
                    key: "selection",
                    fn: function (data) {
                      return [
                        _c("img", {
                          staticStyle: { width: "20px", height: "auto" },
                          attrs: { src: data.item.flag, alt: "" },
                        }),
                        _c("span", { staticClass: "mx-2" }, [
                          _vm._v(_vm._s(data.item.callingCodes[0])),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "item",
                    fn: function (data) {
                      return [
                        _c("img", {
                          staticStyle: { width: "20px", height: "auto" },
                          attrs: { src: data.item.flag, alt: "" },
                        }),
                        _c("span", { staticClass: "mx-2" }, [
                          _vm._v(_vm._s(data.item.name)),
                        ]),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.countryCode,
                  callback: function ($$v) {
                    _vm.countryCode = $$v
                  },
                  expression: "countryCode",
                },
              }),
            ],
            1
          ),
          _c(
            VCol,
            { staticClass: "my-0 py-0", attrs: { cols: "8", md: "8" } },
            [
              _c(VTextField, {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value:
                      _vm.countryCode && _vm.countryCode.callingCodes[0] == "55"
                        ? "(##) #####-####"
                        : "",
                    expression:
                      "\n          countryCode && countryCode.callingCodes[0] == '55'\n            ? '(##) #####-####'\n            : ''\n        ",
                  },
                ],
                staticClass: "my-input-style",
                class: { "invalid-field": !_vm.User.CelPhone },
                staticStyle: { "border-radius": "4px" },
                attrs: {
                  "data-test": "Alpha:InvestorInfo:Celphone",
                  autocomplete: "off",
                  dense: "",
                  rounded: "",
                  rules: [
                    function (v) {
                      return (
                        (!!v &&
                          ((_vm.countryCode &&
                            _vm.countryCode.callingCodes[0] != "55" &&
                            _vm.User.CelPhone.length >= 8) ||
                            (_vm.countryCode &&
                              _vm.countryCode.callingCodes[0] == "55" &&
                              _vm.User.CelPhone.length >= 14))) ||
                        _vm.required()
                      )
                    },
                  ],
                  filled: "",
                  type: "tel",
                },
                on: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "space", 32, $event.key, [
                        " ",
                        "Spacebar",
                      ])
                    ) {
                      return null
                    }
                    $event.preventDefault()
                  },
                },
                model: {
                  value: _vm.User.CelPhone,
                  callback: function ($$v) {
                    _vm.$set(_vm.User, "CelPhone", $$v)
                  },
                  expression: "User.CelPhone",
                },
              }),
            ],
            1
          ),
          _c(
            VCol,
            { staticClass: "mt-3", attrs: { cols: "12" } },
            [
              _c(
                "span",
                {
                  staticClass: "field-header",
                  staticStyle: { color: "var(--dark)" },
                },
                [_vm._v(_vm._s(_vm.$t("password")))]
              ),
              _c(VTextField, {
                ref: "password",
                staticClass: "mb-4 my-input-style",
                attrs: {
                  "data-test": "Alpha:InvestorInfo:Password",
                  dense: "",
                  rounded: "",
                  rules: [
                    function (v) {
                      return (
                        (!!v && _vm.verifyAllPasswordRequirements) ||
                        _vm.required()
                      )
                    },
                  ],
                  type: _vm.show1 ? "text" : "password",
                  "append-icon": _vm.show1 ? "mdi-eye" : "mdi-eye-off",
                  filled: "",
                  autocomplete: "off",
                },
                on: {
                  keyup: _vm.verifyRequirements,
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "space", 32, $event.key, [
                        " ",
                        "Spacebar",
                      ])
                    ) {
                      return null
                    }
                    $event.preventDefault()
                  },
                  "click:append": function ($event) {
                    _vm.show1 = !_vm.show1
                  },
                },
                model: {
                  value: _vm.User.UserPassword,
                  callback: function ($$v) {
                    _vm.$set(_vm.User, "UserPassword", $$v)
                  },
                  expression: "User.UserPassword",
                },
              }),
            ],
            1
          ),
          _c(
            VCol,
            { attrs: { cols: "12" } },
            [
              _c(
                "span",
                {
                  staticClass: "field-header",
                  staticStyle: { color: "var(--dark)" },
                },
                [_vm._v(_vm._s(_vm.$t("password_confirmation")))]
              ),
              _c(VTextField, {
                staticClass: "ma-0 my-input-style",
                attrs: {
                  "data-test": "Alpha:InvestorInfo:PasswordConfirmation",
                  rules: [
                    _vm.passwordConfirmation == _vm.User.UserPassword ||
                      _vm.required(),
                  ],
                  dense: "",
                  rounded: "",
                  type: _vm.show1 ? "text" : "password",
                  "append-icon": _vm.show1 ? "mdi-eye" : "mdi-eye-off",
                  filled: "",
                  autocomplete: "off",
                },
                on: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "space", 32, $event.key, [
                        " ",
                        "Spacebar",
                      ])
                    ) {
                      return null
                    }
                    $event.preventDefault()
                  },
                  "click:append": function ($event) {
                    _vm.show1 = !_vm.show1
                  },
                },
                model: {
                  value: _vm.passwordConfirmation,
                  callback: function ($$v) {
                    _vm.passwordConfirmation = $$v
                  },
                  expression: "passwordConfirmation",
                },
              }),
              _vm.passwordConfirmation != _vm.User.UserPassword &&
              _vm.passwordConfirmation != null
                ? _c(
                    "p",
                    {
                      staticClass: "ma-0",
                      staticStyle: { color: "red", "font-size": "11px" },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("pswds_dnt_match")) + " ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            VCol,
            { staticClass: "mt-5", attrs: { cols: "12" } },
            [
              _c("ValidateStrengthPassword", {
                attrs: { userPassword: _vm.User.UserPassword },
              }),
            ],
            1
          ),
          _c(
            VCol,
            { staticClass: "mt-1 mb-10", attrs: { cols: "12" } },
            [
              _c("PasswordChecklist", {
                ref: "passwordChecklist",
                attrs: { password: _vm.User.UserPassword },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        VRow,
        { staticClass: "ma-0", attrs: { justify: "center" } },
        [
          _c(
            VBtn,
            {
              staticClass: "next-button mx-auto my-1 mb-3",
              staticStyle: {
                "border-radius": "15px",
                width: "333px",
                height: "50px",
              },
              attrs: {
                color: "primary",
                "data-test": "Alpha:InvestorInfo:Submit",
              },
              on: { click: _vm.checkForm },
            },
            [_c("span", [_vm._v(_vm._s(_vm.$t("continue")))])]
          ),
        ],
        1
      ),
      _c(
        VDialog,
        {
          attrs: { persistent: "", width: "300" },
          model: {
            value: _vm.socialNumberDialog,
            callback: function ($$v) {
              _vm.socialNumberDialog = $$v
            },
            expression: "socialNumberDialog",
          },
        },
        [
          _c(
            VCard,
            {
              staticClass: "dialog-bg",
              staticStyle: {
                "border-radius": "10px",
                "background-color": "var(--white) !important",
              },
            },
            [
              _c(
                VRow,
                {
                  staticClass: "pr-6",
                  attrs: { justify: "end", align: "center" },
                },
                [
                  _c(
                    VIcon,
                    {
                      attrs: { color: "black" },
                      on: {
                        click: function ($event) {
                          _vm.socialNumberDialog = false
                        },
                      },
                    },
                    [_vm._v("mdi-close")]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "icon-alert" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/alert-icon.svg"),
                    alt: "alert",
                  },
                }),
              ]),
              _c(
                "h2",
                {
                  staticClass: "pt-3 px-4 pb-3 text-center",
                  staticStyle: { color: "var(--dark)" },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("social_number_already_exists")) + " "
                  ),
                ]
              ),
              _c(
                "p",
                {
                  staticClass: "px-4 text-center",
                  staticStyle: {
                    "max-width": "400px",
                    "letter-spacing": "0.25px",
                    "font-size": "16px",
                    "line-height": "20px",
                    "font-family": "'Source Sans Pro'",
                    color: "var(--dark)",
                    "margin-left": "auto",
                    "margin-right": "auto",
                  },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("if_social_number_is_yours")) + " "
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "alert-create-box" },
                [
                  _c(
                    VBtn,
                    {
                      staticClass: "mb-5",
                      staticStyle: {
                        "border-style": "groove",
                        "border-color": "var(--primary)",
                        "background-color": "var(--white)",
                        color: "var(--primary)",
                        "font-size": "16px",
                        "border-radius": "8px",
                      },
                      attrs: { outlined: "", height: "35", width: "120" },
                      on: {
                        click: function ($event) {
                          _vm.socialNumberDialog = false
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("cancel")))]
                  ),
                  _c(
                    VBtn,
                    {
                      staticClass: "mb-5",
                      staticStyle: {
                        "border-radius": "8px",
                        "background-color": "var(--primary)",
                        color: "var(--white)",
                        border: "none",
                        "font-size": "16px",
                      },
                      attrs: { outlined: "", height: "35", width: "120" },
                      on: { click: _vm.goToLogin },
                    },
                    [_vm._v(_vm._s(_vm.$t("login_txt")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }