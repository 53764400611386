import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "select-portfolio-bg" }, [
    _vm.PrivatePartner
      ? _c("h2", { staticClass: "select-portfolio-title" }, [
          _vm._v(" " + _vm._s(_vm.$t("alpha_register_2_header_tech")) + " "),
        ])
      : _c("h2", { staticClass: "select-portfolio-title" }, [
          _vm._v(" " + _vm._s(_vm.$t("alpha_register_2_header")) + " "),
        ]),
    _c("h3", { staticClass: "select-portfolio-subtitle" }, [
      _vm._v(" " + _vm._s(_vm.$t("alpha_register_2_subheader")) + " "),
    ]),
    _c("div", { staticClass: "items-center" }, [
      _c("ul", { staticClass: "select-portfolio-content-list" }, [
        _c(
          "li",
          [
            _c(VIcon, { attrs: { small: "", color: "primary" } }, [
              _vm._v("mdi-check"),
            ]),
            _c("p", { staticClass: "select-portfolio-content-list-item" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("alpha_register_suggestions_allocations")) +
                  " "
              ),
            ]),
          ],
          1
        ),
        _vm.PrivatePartner
          ? _c(
              "li",
              [
                _c(VIcon, { attrs: { small: "", color: "primary" } }, [
                  _vm._v("mdi-check"),
                ]),
                _c("p", { staticClass: "select-portfolio-content-list-item" }, [
                  _vm._v(" " + _vm._s(_vm.$t("regulated_manager_cvm")) + " "),
                ]),
              ],
              1
            )
          : _vm._e(),
        _vm.PrivatePartner
          ? _c(
              "li",
              [
                _c(VIcon, { attrs: { small: "", color: "primary" } }, [
                  _vm._v("mdi-check"),
                ]),
                _c("p", { staticClass: "select-portfolio-content-list-item" }, [
                  _vm._v(" " + _vm._s(_vm.$t("exclusive_opportunities")) + " "),
                ]),
              ],
              1
            )
          : _vm._e(),
        !_vm.PrivatePartner
          ? _c(
              "li",
              [
                _c(VIcon, { attrs: { small: "", color: "primary" } }, [
                  _vm._v("mdi-check"),
                ]),
                _c("p", { staticClass: "select-portfolio-content-list-item" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("alpha_register_plan_investment")) + " "
                  ),
                ]),
              ],
              1
            )
          : _vm._e(),
        !_vm.PrivatePartner
          ? _c(
              "li",
              [
                _c(VIcon, { attrs: { small: "", color: "primary" } }, [
                  _vm._v("mdi-check"),
                ]),
                _c("p", { staticClass: "select-portfolio-content-list-item" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("alpha_register_exclusive_management")) +
                      " "
                  ),
                ]),
              ],
              1
            )
          : _vm._e(),
        !_vm.PrivatePartner
          ? _c(
              "li",
              [
                _c(VIcon, { attrs: { small: "", color: "primary" } }, [
                  _vm._v("mdi-check"),
                ]),
                _c("p", { staticClass: "select-portfolio-content-list-item" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("alpha_register_active_governance")) +
                      " "
                  ),
                ]),
              ],
              1
            )
          : _vm._e(),
        !_vm.PrivatePartner
          ? _c(
              "li",
              [
                _c(VIcon, { attrs: { small: "", color: "primary" } }, [
                  _vm._v("mdi-check"),
                ]),
                _c("p", { staticClass: "select-portfolio-content-list-item" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("alpha_register_start_investment")) +
                      " "
                  ),
                ]),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]),
    _c("div", { staticClass: "end-box-center" }, [
      _c("div", { staticClass: "pa-5 end-box" }, [
        _c(
          "div",
          [
            _c(
              VBtn,
              {
                staticClass: "ma-0 primary",
                staticStyle: { "border-radius": "15px", "font-size": "24px" },
                attrs: {
                  id: "btn-create-wallet",
                  "data-test": "Alpha:SelectPortfolio:Next",
                  loading: _vm.loading,
                },
                on: { click: _vm.nextStep },
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("alpha_register_create_portfolio")) + " "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }